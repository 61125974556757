
import CloudFun, {
  defineComponent,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import AddressSelectBox from "@/components/address-select-box/Main.vue"

export default defineComponent({
  components: {
    Grid,
    AddressSelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: "景點管理",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      exportConfig: {
        filename: "景點清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        // columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
            if (!params.sortings) params.sortings = [];
            params.sortings.push({ column: "Ordinal", order: 0 });
            return model.dispatch("area/query", params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch("area/query") : undefined,
        save: model ? params => model.dispatch("area/save", params) : undefined
      },
      modalConfig: { showFooter: true, width: "60%" }
      //   treeConfig: { children: "Children" }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 140,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          titleWidth: 140,
          itemRender: {
            name: "$select",
            options: [
              { value: false, label: "否" },
              { value: true, label: "是" }
            ]
          }
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          titleWidth: 140,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" }
          }
        },
        {
          field: "StartTime",
          title: "營業開始時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "time", placeholder: "請輸入開始時間" }
          }
        },
        {
          field: "EndTime",
          title: "營業結束時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "time", placeholder: "請輸入結束時間" }
          }
        },
        {
          field: "HolidayStartTime",
          title: "假日營業開始時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "time", placeholder: "請輸入開始時間" }
          }
        },
        {
          field: "HolidayEndTime",
          title: "假日營業結束時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "time", placeholder: "請輸入結束時間" }
          }
        },
        {
          field: "IntervalTime",
          title: "租借時間刻度(分)",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入時間刻度" }
          }
        },
        {
          field: "NextBookingIntervalInSecs",
          title: "下次可預約時間間隔",
          span: 12,
          slots: { default: "column-next-booking-interval" },
        },
        {
          field: "Address",
          title: "地址",
          span: 24,
          slots: { default: "column-address" }
        },
      ],
      rules: {
        Name: [{ required: true }],
        StartTime: [{ required: true }],
        EndTime: [{ required: true }],
        HolidayStartTime: [{ required: true }],
        HolidayEndTime: [{ required: true }],
        IntervalTime: [{ required: true }],
      }
    };

    return {
      grid,
      gridOptions,
      formOptions
      // classicEditor,
      // editorConfig
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    onGridEdit(row: any, callback: any) {
      row.NextBookingInterval = { Days: 0, Hours: 0, Minutes: 0 };
      if (row.NextBookingIntervalInSecs) {
        let secs = parseInt(row.NextBookingIntervalInSecs);
        if (secs > 86400) {
          row.NextBookingInterval.Days = Math.floor(secs / 86400);
          secs -= row.NextBookingInterval.Days * 86400;
        }
        if (secs > 3600) {
          row.NextBookingInterval.Hours = Math.floor(secs / 3600);
          secs -= row.NextBookingInterval.Hours * 3600;
        }
        row.NextBookingInterval.Minutes = Math.floor(secs / 60);
      }
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      row.NextBookingIntervalInSecs = row.NextBookingInterval.Days * 86400 + row.NextBookingInterval.Hours * 3600 + row.NextBookingInterval.Minutes * 60;
      callback();
    }
  }
});
